// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"

//= require jquery3
//= require jquery_ujs
//= require popper
//= require bootstrap-sprockets
import * as bootstrap from "bootstrap"

import Trix from 'trix'
import { addHeadingAttributes, addForegroundColorAttributes, addBackgroundColorAttributes, RichText } from "./rich_text"
addHeadingAttributes()
// addForegroundColorAttributes()
// addBackgroundColorAttributes()

document.addEventListener("trix-before-initialize", (event) => {
  // Change Trix.config if you need
  autoSaveDraftInterval() // initialize auto save interval
  countWords()
  event.target.addEventListener("keydown", (event) => { countWords() })
})

document.addEventListener("trix-initialize", function (event) {
    new RichText(event.target)
})

document.addEventListener("trix-action-invoke", function (event) {
  if (event.actionName == "x-horizontal-rule") insertHorizontalRule()

  function insertHorizontalRule() {
    event.target.editor.insertAttachment(buildHorizontalRule())
  }

  function buildHorizontalRule() {
    return new Trix.Attachment({ content: "<hr>", contentType: "vnd.rubyonrails.horizontal-rule.html" })
  }
})

document.addEventListener("trix-change", (event) => {
  autoSaveChanges = true
})

var autoSaveChanges = false

const autoSaveDraftInterval = () => {
  setInterval(() => {
    autoSaveDraft();
  }, 15000)
}

const autoSaveDraft = () => {
  if (autoSaveChanges) {
    autoSaveChanges = false
    var draftButton = document.getElementsByName('draft')[0]
    if (draftButton) { draftButton.click() }
  }
}
window.autoSaveDraft = autoSaveDraft

const countChars = (id) => {
  const chars = document.getElementById(id)?.value.length
  const charCountDiv = document.getElementById(`char-count-${id}`)
  if (charCountDiv) {
    charCountDiv.innerHTML = `${chars} / 400`;
  }
}
window.countChars = countChars

const countWords = () => {
  // .replace(/<[^>]*>/g," ")
  // .replace(/\s+/g, ' ')
  // .trim()
  const words = document.getElementById('message_content')?.value
    .replaceAll(/<br>|<[\/]?div>|&nbsp;|\.|,/g, ' ')
    .split(' ')
    .filter((el) => el != '')
    .length

  const wordCountDiv = document.getElementById('word-count')
  if (wordCountDiv) {
    wordCountDiv.innerHTML = `~${Math.floor(words / 10) * 10 + 10}`;
  }
}

import { Controller } from "@hotwired/stimulus"

export default class MessagesNavController extends Controller {
  static targets = ["nav"];

  toggle(event) {
    this.updateUrl(event)
    this.navTargets.forEach(nav => {
      if (nav === event.currentTarget) {
        nav.classList.add("active-message");
      } else {
        nav.classList.remove("active-message");
      }
    });
  }

  updateUrl(event) {
    const url = event.currentTarget.getAttribute("href");
    const state = { turbo_stream_history: true };
    history.replaceState(state, "", url);
  }
}
